<template>
  <Menu>
    <MenuButton
      @click.stop="$emit('update:isOpen', !isOpen)"
      class="w-full focus:outline-none"
      :disabled="disabled"
      ><slot name="trigger"
    /></MenuButton>
    <transition name="dropdown">
      <MenuItems
        class="absolute z-50 bg-white border border-gray-300 border-solid p-1 rounded-1 focus:outline-none w-max"
        v-bind="$attrs"
        :static="isStatic"
        v-if="!isStatic || isOpen"
        v-click-outside="() => $emit('update:isOpen', false)"
      >
        <slot name="dropdown" />
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

export default {
  name: 'dropdown-wrapper',
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
  inheritAttrs: false,
  props: {
    disabled: { type: Boolean, default: false },
    isStatic: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
  },
  emits: ['update:isOpen'],
};
</script>
