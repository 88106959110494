<template>
  <div class="laptop-only" v-if="isVisible">
    <app-button
      variant="base"
      leftIcon="deleteRed"
      class="ml-auto text-tiny text-red-600 font-medium h-bulkEditBtn border border-red-600 px-2 focus:outline-none"
      @click="onEmptyBin"
    >
      {{ $t('campaigns.emptyBin') }}
    </app-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CampaignEvents } from '../events';

export default defineComponent({
  emits: [CampaignEvents.EMPTY_BIN],
  props: {
    isVisible: { type: Boolean, required: true },
  },
  setup(_, context) {
    const onEmptyBin = () => {
      context.emit(CampaignEvents.EMPTY_BIN);
    };
    return { onEmptyBin };
  },
});
</script>
